<template>
  <div class="auth-user-page">
    <base-sign-page
      title-text="用户"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="showData"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :tableActionsWidth="160"
      :show-search="true"
      :loading="loading"
      :title-menus="titleMenus"
      @clickTitleMenu="clickTitleMenu"
      :table-actions-fixed="true"
      @tableAction="tableAction"
      :table-actions="tableActions"
      :need-pagination="true">
    </base-sign-page>
    <user-form ref="form" :org-tree="orgTree" @reload="loadData" :data="chooseData" />
  </div>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'
import UserForm from './user/form.vue'

import {
  userRequest as request,
  resetPassword,
  orgRequest
} from '@/api'

export default {
  components: {
    BaseSignPage, UserForm
  },
  watch: {},
  computed: {
    showData () {
      return this.dataList.map((v) => {
        let roleNames = v.roles.map(v2 => (this.sysRoles.find(v3 => v3.key === v2.id) || {data: {name: ''}}).data.name).filter(v4 => v4).join(',')
        return Object.assign({}, v, {
          roleNames: roleNames
        })
      })
    },
    tableActions: {
      get () {
        return [{
          label: '删除',
          key: 'del'
        },
        {
          label: '修改',
          key: 'edit'
        },
        {
          label: '重置密码',
          key: 'reset_pwd'
        }].filter(({key}) => this.$authFunsProxy[key])
      }
    },
    titleMenus: {
      get () {
        return [{
          key: 'add',
          label: '新增'
        }].filter(({key}) => this.$authFunsProxy[key])
      }
    },
    sysRoles: {
      get () {
        return this.$store.getters.roleList
      }
    },
    columnList: {
      get () {
        return [{
          field: 'id',
          sort: true,
          title: '用户编码'
        },
        {
          field: 'name',
          sort: true,
          title: '用户名'
        },
        {
          field: 'phone',
          sort: true,
          title: '账号'
        },
        {
          field: 'roleNames',
          sort: true,
          title: '角色权限'
        }]
      }
    }
  },
  async created () {
    if (this.$authFunsProxy.edit || this.$authFunsProxy.add) {
      this.$store.dispatch('loadAllWorkerList')
      this.$store.dispatch('loadOrgList')
      this.$store.dispatch('loadRoleList')
      this.loadOrgTree()
    }
    this.loadData()
  },
  methods: {
    async loadOrgTree () {
      this.orgTree = await orgRequest.getTree()
    },
    tableAction (data) {
      this.chooseData = data.data
      if (data.action === 'edit') {
        this.$refs.form.open(data.data)
      } else if (data.action === 'del') {
        this.delData()
      } else if (data.action === 'reset_pwd') {
        this.resetPassword()
      }
    },
    async resetPassword () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定重置用户 ' + this.chooseData.name + ' 的密码吗?'})
      if (result) {
        resetPassword(this.chooseData.id).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '密码重置成功'
          })
        })
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除用户 ' + this.chooseData.name + ' 吗?'})
      if (result) {
        request.del(this.chooseData.id).then(() => {
          this.$notice.info({
            title: '系统提示',
            desc: '用户已删除'
          })
          this.loadData()
          this.$store.dispatch('loadUserList', true)
        })
      }
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    clickTitleMenu (parm) {
      if (parm === 'add') {
        this.$refs.form.open({name: null, phone: null, roleIds: [], roles: []})
      }
    },
    loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      this.loading = true
      let parm = {}
      request.get(parm).then((data) => {
        data.forEach((item) => {
          item.roleIds = []
          item.carTeamIds = []
          item.roleNames = []
          item.roles.forEach((itemRole) => {
            item.roleIds.push(itemRole.id)
            item.roleNames.push(itemRole.name)
          })
          item.roleNames = item.roleNames.join(',')
        })
        this.dataList = data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  data () {
    return {
      orgTree: [],
      dataList: [],
      noteText: '',
      loading: true,
      chooseData: {}
    }
  }
}
</script>
